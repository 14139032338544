import React, { useState } from 'react'
import i18n from '../../i18n'
import './LanguageSelector.css'

const LanguageSelector = () => {
	const [selectedLanguage, setSelectedLanguage] = useState(i18n.language)

	const chooseLanguage = e => {
		e.preventDefault()
		i18n.changeLanguage(e.target.value)
		setSelectedLanguage(e.target.value)
	}

	return (
		<select
			defaultValue={selectedLanguage}
			onChange={chooseLanguage}
			className='language-selector'
		>
			<option className='language-select-option' value='ru'>
				Ru
			</option>
			<option value='en' className='language-select-option'>
				En
			</option>
			<option value='uz' className='language-select-option'>
				Uz
			</option>
		</select>
	)
}

export default LanguageSelector
