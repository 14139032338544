import { ToastContainer } from 'react-toastify'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import Hero from './components/hero/Hero'
import Navbar from './components/navbar/Navbar'
import Service from './components/service/Service'
import Tickets from './components/tickets/Tickets'
import Travel from './components/travel/Travel'

function App() {
	return (
		<>
			<ToastContainer />
			<div className='app'>
				<Navbar />
				<Hero />
				<Service />
				<Tickets />
				<Travel />
				<Contact />
				<Footer />
			</div>
		</>
	)
}

export default App
