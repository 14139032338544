import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationsInEng from '../locales/en/translation.json'
import translationsInGerman from '../locales/ru/translation.json'
import translationsInItalian from '../locales/uz/translation.json'

// the translations
const resources = {
	en: {
		translation: translationsInEng,
	},
	ru: {
		translation: translationsInGerman,
	},
	uz: {
		translation: translationsInItalian,
	},
}

i18n.use(initReactI18next).init({
	resources,
	lng: 'uz',
	debug: true,
	fallbackLng: 'uz',
	interpolation: {
		escapeValue: false,
	},
	ns: 'translation',
	defaultNS: 'translation',
})

export default i18n
