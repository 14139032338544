import emailjs from '@emailjs/browser'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import './Contact.css'

const Contact = () => {
	const { t } = useTranslation()
	const form = useRef()
	const sendEmail = e => {
		e.preventDefault()

		emailjs
			.sendForm(
				'service_74bkg8l',
				'template_5v474jj',
				form.current,
				'alMtirEuU7N45CyBt'
			)
			.then(
				result => {
					form.current.reset()
					toast.success(t('A message has been sent!'))
				},
				error => {
					console.log(error.text)
					toast.error(t('Error sending message, try again!'))
				}
			)
	}

	return (
		<div className='contact py-5' id='contacts'>
			<div className='container'>
				<h1 className='contact-title'>{t('Contact us')}</h1>
				<form ref={form} onSubmit={sendEmail}>
					<div className='form-group mt-4'>
						<input
							name='user_name'
							type='text'
							className='form-control p-3'
							placeholder={t('Please write your name')}
							required
						/>
					</div>
					<div className='form-group mt-4'>
						<input
							name='user_email'
							type='email'
							className='form-control p-3'
							placeholder={t('Please write your email')}
							required
						/>
					</div>
					<div className='form-group mt-4'>
						<textarea
							name='message'
							className='form-control'
							placeholder={t('Please write your message')}
							rows='5'
							required
						></textarea>
					</div>
					<button type='submit' className='btn contact-btn mt-4 w-100 p-3'>
						{t('Send message')}
					</button>
				</form>
			</div>
		</div>
	)
}

export default Contact
