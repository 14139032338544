import React from 'react'
import { useTranslation } from 'react-i18next'
import './Hero.css'

const Hero = () => {
	const { t } = useTranslation()

	const arrowBtn = (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='16'
			fill='currentColor'
			className='bi bi-arrow-right ms-1'
			viewBox='0 0 16 16'
		>
			<path
				fill-rule='evenodd'
				d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8'
			/>
		</svg>
	)

	const heroContent = (
		<>
			<div className='hero-content'>
				<h1>{t('Explore the world with unforgettable emotions')}</h1>
				<p>{t('Adventures, joys, emotions...')}</p>
			</div>
			<button className='hero-content-btn'>
				<a href='#contacts' aria-current='page'>
					{t('Book now')}
					<span className='btn-arrow-icon'>{arrowBtn}</span>
				</a>
			</button>
		</>
	)
	return (
		<div className='container' id='our-tours'>
			<div
				id='carouselExampleAutoplaying'
				className='carousel slide'
				data-bs-ride='carousel'
			>
				<div className='carousel-indicators mb-5'>
					<button
						type='button'
						data-bs-target='#carouselExampleAutoplaying'
						data-bs-slide-to='0'
						class='active'
						aria-current='true'
						aria-label='Slide 1'
					></button>
					<button
						type='button'
						data-bs-target='#carouselExampleAutoplaying'
						data-bs-slide-to='1'
						aria-label='Slide 2'
					></button>
					<button
						type='button'
						data-bs-target='#carouselExampleAutoplaying'
						data-bs-slide-to='2'
						aria-label='Slide 3'
					></button>
				</div>

				<div className='carousel-inner'>
					<div className='carousel-item active' data-bs-interval='5000'>
						<div className='hero slide1'>{heroContent}</div>
					</div>
					<div className='carousel-item' data-bs-interval='5000'>
						<div className='hero slide2'>{heroContent}</div>
					</div>
					<div className='carousel-item' data-bs-interval='5000'>
						<div className='hero slide3'>{heroContent}</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Hero
